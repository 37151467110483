<template>
  <g>
    <svg:style>
      .cls-3, .cls-4, .cls-5 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
      :x1="doorLeftWidth1 + 70.8"
      :y1="doorTopHeight1 + 105.45"
      :x2="doorLeftWidth1 + 70.8"
      :y2="doorTopHeight1 + 45.48"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
      :x1="doorLeftWidth1 + 70.8"
      :y1="doorTopHeight1 + 98.45"
      :x2="doorLeftWidth1 + 70.8"
      :y2="doorTopHeight1 + 52.48"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="S05">
      <polyline id="inox" v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 70.8} ${doorTopHeight1 + 45.48} ${doorLeftWidth1 + 40.82} ${doorTopHeight1 + 75.46} ${doorLeftWidth1 + 70.8} ${doorTopHeight1 + 105.45} ${doorLeftWidth1 + 100.79} ${doorTopHeight1 + 75.46} ${doorLeftWidth1 + 70.8} ${doorTopHeight1 + 45.48}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-5"
                :points="`${doorLeftWidth1 + 70.8} ${doorTopHeight1 + 52.48} ${doorLeftWidth1 + 47.82} ${doorTopHeight1 + 75.46} ${doorLeftWidth1 + 70.8} ${doorTopHeight1 + 98.45} ${doorLeftWidth1 + 93.79} ${doorTopHeight1 + 75.46} ${doorLeftWidth1 + 70.8} ${doorTopHeight1 + 52.48}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
